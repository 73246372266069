<!--
 * @Descripttion:标准高级查询
 * @version:1.0.0
 * @Author: huchongyuan
 * @Date: 2021-03-11 14:18:26
 * @LastEditors: huchongyuan
 * @LastEditTime: 2021-04-21 17:47:14
-->
<template>
   <div class="NormAdvancedQuery">
      <div class="NormAdvancedQueryHeader">
         <Form ref="formInline" :model="integratedQuery" :rules="ruleValidate" label-position="right" :label-width="100" inline>
            <FormItem prop="searchStr" label="查询关键字" class="first">
               <Input type="text" v-model="integratedQuery.searchStr" placeholder="请输入查询关键字" />
            </FormItem>
            <FormItem prop="standTypes" label="查询类别"  class="mornWidth">
               <Select v-model="integratedQuery.standTypes" multiple>
                  <Option v-for="opt in options" :value="opt.statusNo" :key="opt.statusNo">{{opt.statusDesc}}</Option>
               </Select>
            </FormItem>
            <FormItem prop="standClass" label="标准分类">
               <Select v-model="integratedQuery.standClass">
                  <Option v-for="opt in queryOptions" :value="opt.statusNo" :key="opt.statusNo">{{opt.statusDesc}}</Option>
               </Select>
            </FormItem>
            <FormItem class="queryBtn">
               <Button type="primary" @click="handleSubmit">查询</Button>
               <Button  @click="handleReset" style="margin-left:8px;">重置</Button>
            </FormItem>
         </Form>
      </div>
      <div class="NormAdvancedQueryContent">
         <QueryResult ref="QueryResult" :columns="columns" />
      </div>
      <statisticsModal ref="statisticsModal" />
      <PdfModal ref="PdfModal" />
   </div>
</template>
<script>
import QueryResult from '@/components/QueryResult';
import NormAdvancedQuery from '@/api/NormAdvancedQuery';
import statisticsModal from '@/components/statisticsModal';
import PdfModal from '@/components/PdfModal';
export default {
   name:"NormAdvancedQuery",
   components:{
      "QueryResult":QueryResult,
      "statisticsModal":statisticsModal,
      "PdfModal":PdfModal
   },
   computed:{
      options:()=>{
         if(sessionStorage.getItem("DD004")){
            var result = JSON.parse(sessionStorage.getItem("DD004"));
            result.unshift({"statusNo":"-1","statusDesc":"全部"});
            return result;
         }
         return [];
      },
      queryOptions:()=>{
          let defaultResult =  [{"statusNo":"-1","statusDesc":"全部"}];
         if(sessionStorage.getItem("DD003")){
                var result = JSON.parse(sessionStorage.getItem("DD003"));
                return [...defaultResult,...result];
            }
            return defaultResult;
      }
   },
   data(){
      return {
         integratedQuery:{
            "standClass":"-1",
            "standTypes":["-1"],
            "searchStr":""
         },
         columns:[
           {"title":"标准分类","key":"standClassStr","width":150},
           {"title":"序号","key":"indexNo","width":70},
           {"title":"标准号","key":"standNo",
               "width":220,
               "render":(h, params) => {
                  var value = params["row"]["standNo"];
                  let name =  params["row"]["standName"];
                  return h('Tooltip',{props:{placement: 'top'}}, [
                     h('a', {
                           on: {
                              click: () => {
                                 this.$refs["statisticsModal"].open({
                                    "standNo":value,
                                    "modalName":value+"　　　"+name
                                 });
                              }
                           }
                        }, value),
                     h('span', {slot: 'content', style: {whiteSpace: 'normal', wordBreak: 'break-all'}}, "点击查询标准详细数据")
                     ]);
               }
            },
            {"title":"标准名称","key":"standName","width":300,
               "render":(h, params) => {
                  let value = params["row"]["standName"];
                  let fjUrl = params["row"]["fjUrl"];
                  return h('Tooltip',{props:{placement: 'top'}}, [
                     h('a', {
                           on: {
                              click: () => {
                                 this.$refs["PdfModal"].open(fjUrl);
                              }
                           }
                        }, value),
                     h('span', {slot: 'content', style: {whiteSpace: 'normal', wordBreak: 'break-all'}}, "点击查询标准原文")
                     ]);
               }
            },
         //   {"title":"标准号","key":"standNo","width":220},
         //   {"title":"标准名称","key":"standName","width":300},
           {"title":"查询内容","key":"standContent","width":450},
           {"title":"查询分类","key":"standTypeStr","width":150},
           {"title":"备注说明","key":"standDesc"}
         ],
         ruleValidate:{
            searchStr:{ required: true, message:'请输入查询内容', trigger: 'blur' }
         },
      }
   },
   mounted(){
      
   },
   activated(){
      // 获取查询参数;
      if(this.$route.params.searchStr){
         let {searchStr} = this.$route.params
        this.$set(this.integratedQuery,"searchStr",searchStr);
      }
      if(this.$route.params.standClass){
        let {standClass} = this.$route.params;
        this.$nextTick(function(){
         this.$set(this.integratedQuery,"standClass",standClass);
        })
      }
      if(this.$route.params.query && this.$route.params.query=="1"){
         this.handleSubmit();
      }

   },
   methods:{
      handleSubmit(){
         this.$refs["formInline"].validate((valid) => {
            if (valid) {
                let requestParam = JSON.parse(JSON.stringify(this.integratedQuery));
                if(requestParam['standTypes'].map){
                  let index = requestParam['standTypes'].indexOf("-1");
                  if(index != -1){
                     requestParam['standTypes'].splice(index,1,"");
                  }
                  if(requestParam["standClass"] == -1){
                     requestParam["standClass"]="";
                  }
                  requestParam['standTypes'] = requestParam['standTypes'].join(",");
                }else{
                   requestParam['standTypes']="";
                }
                this.$refs["QueryResult"].query(NormAdvancedQuery.query,requestParam,true);
            } 
         })
      },
      handleReset(){
         this.$set(this,'integratedQuery',{
            "standClass":"-1",
            "standTypes":["-1"],
            "searchStr":""
         })
      }
   }
}
</script>
<style lang="less">
.NormAdvancedQueryHeader .first .ivu-form-item-content{
    width:310px;
}
.NormAdvancedQueryHeader .ivu-form-item-content{
    width:186px;
}
.NormAdvancedQueryHeader .mornWidth .ivu-form-item-content{
   width:350px;
}
.NormAdvancedQueryHeader .queryBtn .ivu-form-item-content{
   margin-left:15px !important;   
}
</style>